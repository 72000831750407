import { AfterViewInit, Component } from '@angular/core';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Observable } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';
import { UserData } from '../userdata';
import { AuthService } from '../auth.service'
import { LoadingshadeService } from '../loadingshade.service'
import { LocalisationService } from '../localisation.service'

@Component({
  selector: 'app-nav',
  templateUrl: './nav.component.html',
  styleUrls: ['./nav.component.css']
})
export class NavComponent implements AfterViewInit {

  isHandset$: Observable<boolean> = this.breakpointObserver.observe(Breakpoints.Handset)
    .pipe(
      map(result => result.matches),
      shareReplay()
    );

  constructor(private breakpointObserver: BreakpointObserver, public user: UserData, public auth: AuthService, public nav:LoadingshadeService, public localisation:LocalisationService) {}

  ngAfterViewInit(){ 
    
    setTimeout(() => {
      this.localisation.refreshData();
    },0)
  }


}
