import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '../auth.service';
import { NotifierService } from '../notifier.service';
import { UserData } from '../userdata';
import { HttpClient } from '@angular/common/http';
import { LocalisationService } from '../localisation.service'
import { PasswordresetComponent } from '../passwordreset/passwordreset.component'
import { MatDialog } from '@angular/material/dialog';


@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})

export class LoginComponent {

  isLoggingIn = false

  constructor(private Auth: AuthService, 
    private notifierService:NotifierService, 
    private router: Router, 
    public user: UserData, 
    private http:HttpClient,
    public localisation:LocalisationService,
    public dialog: MatDialog) {}
  
  ngOnInit(){    
 
    if(this.user.userID) {
      if(localStorage.getItem("lastPage")) {
        this.router.navigate([localStorage.getItem("lastPage")])
      } else {
        this.router.navigate(["/home"])
      }
    }
  }

  loginUser(event: any) {
    
    event.preventDefault(); // disable page refresh
    const target = event.target
    const email = target.querySelector('#l-mail').value.trim()
    const password = target.querySelector('#l-pw').value

    if(email && password) {
      this.isLoggingIn = true
      this.Auth.login(email, password).subscribe(
        data => {
          //console.log("Hi " + data.name + " ID:" + data.userID)
          this.user.userID = data.userID
          this.user.name = data.name
          this.user.email = data.email
          this.user.company = data.company
          this.user.language = data.language
          this.user.role = data.role
          this.user.active = data.active
          this.user.contact_id = data.contact_id
          localStorage.setItem("loggedIn", "true")
          
          if(localStorage.getItem("lastPage") == "/login" || localStorage.getItem("lastPage") == "/" || !localStorage.getItem("lastPage")) {
            this.router.navigate(["/home"])
          } else {
            this.router.navigate([localStorage.getItem("lastPage")])
          }
      }, 
        error => {
          this.isLoggingIn = false
          this.notifierService.showNotification(error.error, "error");
        }
      )
    } else {
      this.isLoggingIn = false
      this.notifierService.showNotification(this.localisation.content.login_nocred, "error");
    }
  }

  passwordlost() {
    this.dialog.open(PasswordresetComponent);
  }
}
