import { NgModule, LOCALE_ID } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { MatButtonModule } from '@angular/material/button';
import { MatMenuModule } from '@angular/material/menu';
import { NavComponent } from './nav/nav.component';
import { LayoutModule } from '@angular/cdk/layout';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { HomeComponent } from './home/home.component';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatCardModule } from '@angular/material/card';
import { LoginComponent } from './login/login.component';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatRadioModule } from '@angular/material/radio';
import { ReactiveFormsModule } from '@angular/forms';

import { provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { NotifierComponent } from './notifier/notifier.component';
import { AuthGuard } from './auth.guard';
import { AuthService } from './auth.service';
import { AddentryComponent } from './addentry/addentry.component';
import { MyentriesComponent } from './myentries/myentries.component';
import { AllentriesComponent } from './allentries/allentries.component';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { ManageComponent } from './manage/manage.component';
import { UserData } from './userdata';
import { MatAutocompleteModule} from '@angular/material/autocomplete';
import { MatDatepickerModule} from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { MatTableModule } from '@angular/material/table';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSortModule } from '@angular/material/sort';
import {FilehandlerService} from './filehandler.service';

import {enableProdMode} from '@angular/core';
import { MatDialogModule } from '@angular/material/dialog';
import { DialogUserComponent } from './dialog-user/dialog-user.component';
import {MatAccordion} from '@angular/material/expansion';
import {MatExpansionModule} from '@angular/material/expansion';
import { environment } from '../environments/environment';
import { SetpasswordComponent } from './setpassword/setpassword.component';
import { PasswordresetComponent } from './passwordreset/passwordreset.component';



import { registerLocaleData } from '@angular/common';
import localeIt from '@angular/common/locales/it';
import localeItExtra from '@angular/common/locales/extra/it';
import localeDe from '@angular/common/locales/de';
import localeDeExtra from '@angular/common/locales/extra/de';
import localeEs from '@angular/common/locales/es';
import localeEsExtra from '@angular/common/locales/extra/es';
import localeFr from '@angular/common/locales/fr';
import localeFrExtra from '@angular/common/locales/extra/fr';
import { DialogReportComponent } from './dialog-report/dialog-report.component';
import { ExportComponent } from './export/export.component';

registerLocaleData(localeIt, localeItExtra, 'it');
registerLocaleData(localeDe, localeDeExtra, 'de');
registerLocaleData(localeEs, localeEsExtra, 'es');
registerLocaleData(localeFr, localeFrExtra, 'fr');

//enableProdMode();

@NgModule({ declarations: [
        AppComponent,
        NavComponent,
        HomeComponent,
        LoginComponent,
        NotifierComponent,
        AddentryComponent,
        MyentriesComponent,
        AllentriesComponent,
        ManageComponent,
        DialogUserComponent,
        SetpasswordComponent,
        PasswordresetComponent,
        DialogReportComponent,
        ExportComponent,
    ],
    bootstrap: [AppComponent], imports: [BrowserModule,
        AppRoutingModule,
        BrowserAnimationsModule,
        MatButtonModule,
        MatMenuModule,
        LayoutModule,
        MatToolbarModule,
        MatSidenavModule,
        MatIconModule,
        MatListModule,
        MatGridListModule,
        MatCardModule,
        MatInputModule,
        MatSelectModule,
        MatRadioModule,
        ReactiveFormsModule,
        MatSnackBarModule,
        MatProgressSpinnerModule,
        MatProgressBarModule,
        MatAutocompleteModule,
        MatDatepickerModule,
        MatNativeDateModule,
        MatTableModule,
        MatPaginatorModule,
        MatSortModule,
        MatDialogModule], providers: [
        AuthGuard,
        MatDatepickerModule,
        { provide: LOCALE_ID, useValue: navigator.language || "en-US" },
        provideHttpClient(withInterceptorsFromDi())
    ] }) 
export class AppModule { }
