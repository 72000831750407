<div class="formbody">
    <mat-form-field appearance="fill">
        <mat-label>{{localisation.content.app_search}}</mat-label>
        <input matInput (keyup)="applyFilter($event)" placeholder="Ex. BG19-..." #input>
      </mat-form-field>

      <div>
        <table mat-table matSort multiTemplateDataRows [dataSource]="dataSource" matSortActive="date" matSortDirection="desc" (matSortChange)="resetPaging()">
            <!-- START Columns def -->
            <!-- ID Column -->
            <ng-container matColumnDef="reportID">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>ID</th>
                <td mat-cell *matCellDef="let row"> {{row.reportID}} </td>
            </ng-container>
        
            <!-- Job Column -->
            <ng-container matColumnDef="job">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>{{localisation.content.entry_jobno}}</th>
                <td mat-cell *matCellDef="let row"> {{row.job}} - {{row.jobdesc}} </td>
            </ng-container>
        
            <!-- date Column -->
            <ng-container matColumnDef="date">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>{{localisation.content.entry_date}}</th>
                <td mat-cell *matCellDef="let row"> {{row.date.date | date:'shortDate'}} </td>
            </ng-container>
        
            <!-- work Column -->
            <ng-container matColumnDef="work">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>{{localisation.content.entry_work}}</th>
                <td mat-cell *matCellDef="let row"> {{row.work}} </td>
            </ng-container>
            <!-- END Columns def -->

      
            <!-- START Row data -->
            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let element; columns: displayedColumns;" class="element-row" [class.expanded-row]="expandedElement === element"
            (click)="getFiles(element.ID); expandedElement = expandedElement === element ? null : element"></tr>
            <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="detail-row"></tr>

            <!-- Expanded Content Column - The detail row is made up of this one column that spans across all columns -->
            <ng-container matColumnDef="expandedDetail">
                <td mat-cell *matCellDef="let element" [attr.colspan]="displayedColumns.length">
                <div class="element-detail" [@detailExpand]="element == expandedElement ? 'expanded' : 'collapsed'">
                    <div class="element-detail-content">
                        <h3>{{localisation.content.entry_detailsheader}}: {{element.job}} - {{element.jobdesc}} No: {{element.reportID}}  <button title="Duplicate" type="submit" mat-flat-button color="secondary" class="button_duplicate" (click)="clone(element)"><mat-icon>content_copy</mat-icon></button></h3>
                        <hr />

                        <div class="element-detail-content-section">
                            <!--<p><span>{{localisation.content.entry_orderno}}:</span> {{element.purchase}}</p>-->
                            <p><span>{{localisation.content.entry_temperature}}:</span> {{element.temperature}} C°</p> 
                            <p><span>{{localisation.content.entry_work}}:</span> {{element.work}}</p>
                            <p><span>{{localisation.content.entry_date}}:</span> {{element.date.date | date:'fullDate'}}</p>        
                            <p><span>{{localisation.content.entry_workerq}}:</span> {{element.worker_quantity}}</p>  
                            <p><span>{{localisation.content.entry_worktime}}:</span> {{element.work_time}}</p>
                            <p><span>{{localisation.content.entry_weather}}:</span> {{element.weather}}</p>
                            <p><span>{{localisation.content.entry_perfwork}}:</span></p>
                            <ul>
                               <li *ngFor="let details of element.performed_work">{{details.name}}</li> 
                            </ul>
                        </div>

                        <div class="element-detail-content-section">
                            <p><span>{{localisation.content.entry_deliveryq}}:</span> {{element.deliveries_quantity}}</p>
                            <p><span>{{localisation.content.entry_deliverycom}}:</span><br /> {{element.deliveries_comments}}</p>
                            <p><span>{{localisation.content.entry_perfworkcom}}:</span><br /> {{element.performed_works_comments}}</p>
                            <p><span>{{localisation.content.entry_othercom}}:</span><br /> {{element.other_comments}}</p>
                            <p><span>{{localisation.content.entry_files}}:</span></p>
                            <p *ngIf="files.length == 0">{{localisation.content.app_nofilesfound}}</p>
                            <ul *ngFor="let file of files">
                                <li>
                                    <a (click)="openAsWindow(file.url)">{{file.name}}</a>
                                </li>
                            </ul>
                        </div>

                        <div class="element-detail-content-section">
                            <p><span>{{localisation.content.report_header}}:</span></p>
                            <ul>
                                <li *ngFor="let report of element.reports"><a target="_blank" href="{{report.link}}">{{report.name}}</a></li>
                            </ul>
                            <p *ngIf="!element.reports?.length">{{localisation.content.app_noreports}}</p>

                        </div>
                        
                    </div>
                </div>
                </td>
            </ng-container>
            <!-- END Row data -->
            

            <!-- This rows are beeing shown when we don't get any data from the server -->
            <tr class="mat-row" *matNoDataRow>
                <td *ngIf="input.value" class="mat-cell" colspan="4">{{localisation.content.app_nothingfoundfor}} "{{input.value}}"</td>
                <td *ngIf="!input.value" class="mat-cell" colspan="4">{{localisation.content.app_nothingfound}}!</td>
            </tr>
        </table>
      
        <mat-paginator [length]="resultsLength" [pageSizeOptions]="[10, 25, 100]"></mat-paginator>
      </div>
</div>
