import { Injectable, Inject } from "@angular/core";


@Injectable({ providedIn: 'root' })
export class UserData {
    
    public userID!: string;
    public name!: string;
    public email!: string;
    public company!: string;
    public role!: number;
    public language!: string;
    public active!: number;
    public contact_id!: string;

}
