<div class="grid-container">
  <h1 class="mat-h1">Manage</h1>

    <mat-card appearance="outlined" class="dashboard-card">
      <mat-card-header>
        <mat-card-title>Users</mat-card-title>
      </mat-card-header>
      <mat-card-content>
        <div class="loading-shade" *ngIf="isLoadingResults">
          <mat-spinner *ngIf="isLoadingResults"></mat-spinner>
        </div>

        <mat-form-field appearance="fill" >
          <mat-label>{{localisation.content.app_search}}</mat-label>
          <input matInput (keyup)="SearchUsers($event)" placeholder="Search for names, e-mail or companies..." #input>
        </mat-form-field>

        
        <div class="table">

          <table  mat-table [dataSource]="userdata">
            
            <ng-container matColumnDef="name">
              <th mat-header-cell *matHeaderCellDef>Name</th>
              <td mat-cell *matCellDef="let row">{{row.name}}</td>
            </ng-container>

            
            <ng-container matColumnDef="email">
              <th mat-header-cell *matHeaderCellDef>E-Mail</th>
              <td mat-cell *matCellDef="let row">{{row.email}}</td>
            </ng-container>

            
            <ng-container matColumnDef="company">
              <th mat-header-cell *matHeaderCellDef>Company</th>
              <td mat-cell *matCellDef="let row">{{row.company}}</td>
            </ng-container>

            <ng-container matColumnDef="actions">
              <th mat-header-cell *matHeaderCellDef>Actions</th>
              <td mat-cell *matCellDef="let row"><button (click)="openUser(row, input)" mat-button><mat-icon>edit</mat-icon></button></td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns_users"></tr>
            <tr class="element-row" mat-row *matRowDef="let row; columns: displayedColumns_users;"></tr>

            <tr class="mat-row" *matNoDataRow>
              <td *ngIf="!input.value" class="mat-cell" colspan="4">{{localisation.content.app_nothingfound}}!</td>
            </tr>

          </table>

        </div>

        <button mat-button color="primary" (click)="openUser('NEW', input)"><mat-icon>add</mat-icon> Add external user</button>
        

      </mat-card-content>

    </mat-card>

    <mat-card appearance="outlined" class="dashboard-card">
      <mat-card-header>
        <mat-card-title>
          Performed works
        </mat-card-title>
      </mat-card-header>
      <mat-card-content>
        Here are the p works...
      </mat-card-content>
    </mat-card>

    <mat-card appearance="outlined" class="dashboard-card">
      <mat-card-header>
        <mat-card-title>
          Weather
        </mat-card-title>
      </mat-card-header>
      <mat-card-content>
        Here are the weathers...
      </mat-card-content>
    </mat-card>

    <mat-card appearance="outlined" class="dashboard-card">
      <mat-card-header>
        <mat-card-title>
          Works
        </mat-card-title>
      </mat-card-header>
      <mat-card-content>
        Here are the works...
      </mat-card-content>
    </mat-card>




</div>
