import { Component } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { UserData } from './userdata';
import { Router } from '@angular/router';
import { NotifierService } from './notifier.service';
import { LocalisationService } from './localisation.service'
import { timeout } from 'rxjs/operators';




@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'app';

  constructor(public http: HttpClient, public user: UserData, private router: Router, private notifierService:NotifierService, public localisation:LocalisationService) {}

  // This code runs on page load
  ngOnInit(){    


    this.localisation.refreshData();

    console.log("// -------------------------------------------------- \\\\")
    console.log("||                 -> Welcome <-                      ||")
    console.log("||  This WebApp was developed by Tobias Fink in 2021. ||")
    console.log("||            (c) Rabensteiner GmbH                   ||")
    console.log("\\\\ -------------------------------------------------- //")  
    
    //if(localStorage.getItem("loggedIn") == "true") {
      const httpOptions = {
        headers: new HttpHeaders({
          'Content-Type':  'application/json',
          //Authorization: 'Token ' + localStorage.getItem("userToken")
        })
      };
      this.http.get<UserData>('/api/v1/self', httpOptions).subscribe(
        data => {
          //console.log("Welcome back " + data.name + ", ID: " + data.userID)
          this.user.userID = data.userID
          this.user.name = data.name
          this.user.email = data.email
          this.user.company = data.company
          this.user.language = data.language
          this.user.role = data.role
          this.user.active = data.active
          this.user.contact_id = data.contact_id
          
          if(localStorage.getItem("lastPage") == "/login" || localStorage.getItem("lastPage") == "/" || !localStorage.getItem("lastPage")) {
            this.router.navigate(["/home"])
          } else {
            this.router.navigate([localStorage.getItem("lastPage")])
          }

        },
        error => {
          if (!window.navigator.onLine) {
            this.router.navigateByUrl("assets/offline.html")
          } else {
            if(window.location.pathname !== "/setpassword") {
              localStorage.clear();
              this.router.navigate(["/login"])
            }           
          }
          
        }
      )

    //} else if(window.location.pathname !== "/setpassword") {
    //  localStorage.setItem("lastPage", window.location.pathname)
    //  this.router.navigate(["/login"])
    //} 
  }
}