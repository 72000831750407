import { AfterViewInit, Component, ViewChild } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Logentry } from '../logentry'
import { NotifierService } from '../notifier.service'
import { LoadingshadeService } from '../loadingshade.service'
import { LocalisationService } from '../localisation.service'
import { Router } from '@angular/router'
import { UserData } from '../userdata';
import { DialogReportComponent } from '../dialog-report/dialog-report.component';
import { merge, Observable, of as observableOf } from 'rxjs';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatDialog } from '@angular/material/dialog';
import { startWith, switchMap, map, catchError } from 'rxjs/operators';



@Component({
  selector: 'app-export',
  templateUrl: './export.component.html',
  styleUrl: './export.component.css'
})
export class ExportComponent implements AfterViewInit {

  displayedColumns:string[] = ["No", "Description", "Manager", "download"];
  dataSource!: Observable<any>;
  resultsLength = 0;
  files:any = [];
  lastID!:number;
   

  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;


  constructor(
    private http: HttpClient, 
    private notifier: NotifierService, 
    public nav:LoadingshadeService, 
    public localisation:LocalisationService, 
    public router: Router, 
    public user: UserData,
    private dialog: MatDialog) { }


    ngAfterViewInit() {
      setTimeout(() => { // resolves error "NG0100: Expression has changed after it was checked" https://angular.io/errors/NG0100
        this.nav.isLoadingResults = true;
        localStorage.setItem("lastPage", "/home/export");
      },0)

  
      this.getData();
      
    }


    resetPaging() {
      this.paginator.pageIndex = 0;
    }


    getEntries(search:string, sort: string, order: string, page: number, limit: number): Observable<any> {
      page++;
      return this.http.get<any>("/api/v1/export-jobs?search="+search+"&sort="+sort+"&order="+order+"&page="+ page+"&limit="+limit, {observe : 'response'});
    }

    getData() {
      setTimeout(() => { // resolves error "NG0100: Expression has changed after it was checked" https://angular.io/errors/NG0100
        this.dataSource = merge(this.sort.sortChange, this.paginator.page).pipe(
          startWith({}),
            switchMap(() => {
              return this.getEntries("", this.sort.active, this.sort.direction, this.paginator.pageIndex, this.paginator.pageSize);
            }),
            map(data => {
              // Flip flag to show that loading has finished.
              this.nav.isLoadingResults = false;
              this.resultsLength = data.body.length;
              if(!data) {
                //this.notifier.showNotification("Error while getting data from the server! Please log in.", "error");
              }
              return data.body;
            }),
            catchError((data) => {
              this.nav.isLoadingResults = false;
              if(data.status == "403") {
                console.log("Logged out. Refreshing app...")
                window.location.reload();
              }
              return observableOf([]);
            })
        )
      }, 0)
    }


    applyFilter(event: Event) {
      this.nav.isLoadingResults = true;
      const filterValue = (event.target as HTMLInputElement).value;
  
      this.dataSource = merge(this.sort.sortChange, this.paginator.page).pipe(
        startWith({}),
          switchMap(() => {
            this.nav.isLoadingResults = true;
            return this.getEntries(filterValue, this.sort.active, this.sort.direction, this.paginator.pageIndex, this.paginator.pageSize);
          }),
          map(data => {
            // Flip flag to show that loading has finished.
            this.nav.isLoadingResults = false;       
            return data.body;
          }),
          catchError((data) => {
            this.nav.isLoadingResults = false;
            if(data.status == "403") {
              console.log("Logged out. Refreshing app...")
              window.location.reload();
            }
            return observableOf([]);
          })
      )
    
      this.paginator.firstPage();
      
    }


    export(link: string) {
      window.open(link,'File'); 
      
    }

}
