import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AddentryComponent } from './addentry/addentry.component';
import { AllentriesComponent } from './allentries/allentries.component';
import { AuthGuard } from './auth.guard';
import { HomeComponent } from './home/home.component';
import { LoginComponent } from './login/login.component';
import { ManageGuard } from './manage.guard';
import { ManageComponent } from './manage/manage.component';
import { MyentriesComponent } from './myentries/myentries.component';
import { NavComponent } from './nav/nav.component';
import { SetpasswordComponent } from './setpassword/setpassword.component';
import { ExportComponent } from './export/export.component';

const routes: Routes = [
  { path: 'home', component: NavComponent, canActivate: [AuthGuard], children:[
    { path: 'add', component: AddentryComponent},
    { path: 'my', component: MyentriesComponent},
    { path: 'all', component: AllentriesComponent},
    { path: 'export', component: ExportComponent},
    { path: 'manage', component: ManageComponent, canActivate: [ManageGuard]},
    { path: '', component: HomeComponent}
  ]},
  { path: 'login', component: LoginComponent},
  { path: 'setpassword', component: SetpasswordComponent },
  { path: '', component: LoginComponent },
  { path: '**', redirectTo:'/login', pathMatch:'full' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
}) 
export class AppRoutingModule { }
