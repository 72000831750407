import { Component, OnInit, Inject, AfterViewInit, ViewChild } from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog'
import { FormArray, UntypedFormControl, UntypedFormGroup, ReactiveFormsModule, Validators  } from '@angular/forms';
import { LoadingshadeService } from '../loadingshade.service'
import { LocalisationService } from '../localisation.service'
import { HttpClient, HttpHeaders } from '@angular/common/http';
import {NotifierService} from '../notifier.service'
import { Router } from '@angular/router';

@Component({
  selector: 'app-passwordreset',
  templateUrl: './passwordreset.component.html',
  styleUrls: ['./passwordreset.component.css']
})
export class PasswordresetComponent implements OnInit {

  constructor(public dialog: MatDialogRef<PasswordresetComponent>,@Inject(MAT_DIALOG_DATA) public data:any, private http: HttpClient, private notifier: NotifierService, private router:Router) { }
  isLoading = false
  passwordreset = new UntypedFormGroup({
    email: new UntypedFormControl('', Validators.compose([Validators.email,Validators.required])) 
  })

  ngOnInit(): void {
  }

  submit() {
    if(this.passwordreset.valid) {
      var regex = new RegExp('^.*@rabensteiner\.(eu|it)$')

      if(regex.test(this.passwordreset.get('email')?.value)) {
        this.notifier.showNotification("You cannot reset your password! Please contact your administrator.", "error")
      } else {
        this.isLoading = true
        let datatopost = JSON.stringify(this.passwordreset.getRawValue())
        
        const httpOptions = {
          headers: new HttpHeaders({
            'Content-Type':  'application/json',
            'observe' : 'response'
          })
        };
  
        
        this.http.put<any>("/api/v1/recoverpassword", datatopost, httpOptions).subscribe(data => {
          this.notifier.showNotification("If we have a user account with this E-Mail, you will get an E-Mail with a recovery link", "success")
          this.dialog.close();
        }, error => {
          this.notifier.showNotification(error.error, "error")
          this.isLoading = false
        })
      }
      
    }
  }

}
