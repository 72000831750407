<h2 mat-dialog-title *ngIf="data.ID">Edit User: {{data.name}}</h2>
<h2 mat-dialog-title *ngIf="!data.ID">New User</h2>

<mat-dialog-content>
    <form [formGroup]="user" (submit)="submit()">

        <mat-form-field class="textinput" appearance="fill" *ngIf="data.ID">
            <mat-label>ID</mat-label>
            <input matInput disabled [value]="data.ID" >
        </mat-form-field>

        <mat-form-field class="textinput" appearance="fill">
            <mat-label>Name</mat-label>
            <input [readonly]="!externaluser" type="text" matInput formControlName="name" >
        </mat-form-field>

        <mat-form-field class="textinput" appearance="fill">
            <mat-label>E-Mail</mat-label>
            <input [readonly]="!externaluser" type="email" matInput formControlName="email" >
        </mat-form-field>

        <mat-form-field class="textinput" appearance="fill">
            <mat-label>Company</mat-label>
            <input [readonly]="!externaluser" type="text" matInput formControlName="company" >
        </mat-form-field>

        <mat-form-field class="textinput" appearance="fill">
            <mat-label>Role</mat-label>
            <mat-select  required formControlName="role" >
                <mat-option [value]="0" >External user</mat-option>
                <mat-option [value]="3" >Employee</mat-option>
                <mat-option [value]="5" >Project manager</mat-option>
                <mat-option [value]="6" >Constructor</mat-option>
                <mat-option [value]="7" >Key user</mat-option>
                <mat-option [value]="10" >Admin</mat-option>
              </mat-select>
        </mat-form-field>

        <mat-form-field class="textinput" appearance="fill">
            <mat-label>Language</mat-label>
            <mat-select  required formControlName="language" >
                <mat-option value='de' >DE</mat-option>
                <mat-option value="it" >IT</mat-option>
                <mat-option value="en" >EN</mat-option>
              </mat-select>
        </mat-form-field>

        <mat-form-field class="textinput" appearance="fill" *ngIf="data.ID">
            <mat-label>Login State</mat-label>
            <mat-select [disabled]="data.active==2"  required formControlName="active"  >
                <mat-option [value]="0" >disabled</mat-option>
                <mat-option [value]="1" >active</mat-option>
                <mat-option [value]="2" *ngIf="externaluser && data.active==2" >email verification required</mat-option>
              </mat-select>
        </mat-form-field>


        <mat-form-field class="textinput" formGroupName="contact_id" appearance="fill" *ngFor="let comp of companies">
            <mat-label>Contact ID {{comp.name}}:</mat-label>
            <input type="text" matInput formControlName="{{comp.ID}}" >
        </mat-form-field>
  
        <br />
        <button type="submit" mat-button >Save</button>
        <button type="button" (click)="cancel()" mat-button>Cancel</button>
        <button *ngIf="data.active==2" type="button" (click)="resendMail(data.ID)" mat-button>Resend invite E-Mail</button>
        <!--<button mat-button *ngIf="data.active==1 && externaluser" (click)="passwordReset(data.email)">Send Password reset link</button>-->
        
    </form>
</mat-dialog-content>


