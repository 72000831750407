import { Component, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpClient, HttpEventType, HttpHeaders, HttpResponse } from '@angular/common/http';
import {NotifierService} from '../notifier.service'


@Component({
  selector: 'app-setpassword',
  templateUrl: './setpassword.component.html',
  styleUrls: ['./setpassword.component.css']
})
export class SetpasswordComponent implements OnInit {

  constructor(
    private route: ActivatedRoute,  
    private router: Router, 
    private http: HttpClient,
    private notifier: NotifierService
    ) { }

  updateuser = new UntypedFormGroup({
    u: new UntypedFormControl('', [Validators.required]),
    k: new UntypedFormControl('', [Validators.required]),
    p: new UntypedFormControl('', Validators.compose([Validators.pattern('^([A-Z,a-z,0-9,Ä,Ö,Ü,ä,ö,ü,À,Ò,Ù,à,ò,ù,Á,Ó,Ú,á,ó,ú,-,?,.,!,/,°,+,*,#,§,$,%,&,(,),=,~, ,@,-]{8,30})$'), Validators.required]))
  })

  hide = true;
  isLoading = false;

  ngOnInit(): void {
    this.route.queryParams
      .subscribe(params => {
        
        this.updateuser.get('u')?.setValue(params.u)
        this.updateuser.get('k')?.setValue(params.k)
        
        if(!params.u || !params.k) {
          this.router.navigate(["/login"])
        } 
      }
    );
  
  }

  submitUser() {
    if(this.updateuser.valid) {
      this.isLoading = true
      let datatopost = JSON.stringify(this.updateuser.getRawValue())
      
      const httpOptions = {
        headers: new HttpHeaders({
          'Content-Type':  'application/json',
          'observe' : 'response'
        })
      };

      
      this.http.put<any>("/api/v1/setpassword", datatopost, httpOptions).subscribe(data => {
        this.notifier.showNotification("User updated!", "success")
        this.router.navigate(["/login"])
      }, error => {
        this.notifier.showNotification(error.error, "error")
        this.isLoading = false
      })


    }

  }


}
