import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpEvent, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { LogentryFiles } from './logentry-files';
import { LoadingshadeService } from './loadingshade.service'


@Injectable({
  providedIn: 'root'
})
export class FilehandlerService {

  private baseUrl = '/api/v1/file';

  constructor(private http: HttpClient, private nav: LoadingshadeService) { }

  upload(file: File, entrienr:number) {
    this.nav.isLoadingResults = true;
    this.nav.isUploadingFiles = true;
    let data = "";
    let name = file.name;
    let type = "";

    const reader = new FileReader();
    reader.readAsDataURL(file)
    reader.onload = (e) => {
      type = reader.result?.toString().split(",")[0].split(";")[0].split(":")[1] || "none"
      data = reader.result?.toString().split(",")[1] || "none"
      
      const httpOptions = {
        headers: new HttpHeaders({
          'Content-Type':  'application/json',
        })
      };
  
      this.http.post<any>('/api/v1/file/'+entrienr, { name, type, data } , httpOptions).subscribe(
        data => {
          this.nav.isLoadingResults = false;
          this.nav.isUploadingFiles = false;
        }
      )
    }
  }

  getFiles(entrienr:number): Observable<any> {
    return this.http.get(`${this.baseUrl}/${entrienr}`);
  }

}
