<div class="formbody">
    <mat-form-field appearance="fill">
        <mat-label>{{localisation.content.app_search}}</mat-label>
        <input matInput (keyup)="applyFilter($event)" placeholder="Ex. BG19-..." #input>
    </mat-form-field>

      <div>
        <table mat-table matSort multiTemplateDataRows [dataSource]="dataSource" matSortActive="date" matSortDirection="desc" (matSortChange)="resetPaging()">
            <!-- Position Column -->
            <ng-container matColumnDef="No">
                <th mat-header-cell *matHeaderCellDef>{{localisation.content.entry_jobno}}</th>
                <td mat-cell *matCellDef="let element"> {{element.No_}} </td>
            </ng-container>
            
            <!-- Name Column -->
            <ng-container matColumnDef="Description">
                <th mat-header-cell *matHeaderCellDef>{{localisation.content.entry_jobdesc}}</th>
                <td mat-cell *matCellDef="let element"> {{element.Description}} </td>
            </ng-container>

            <!-- Name Column -->
            <ng-container matColumnDef="Manager">
                <th mat-header-cell *matHeaderCellDef>{{localisation.content.entry_jobmanager}}</th>
                <td mat-cell *matCellDef="let element"> {{element.person_responsible}} </td>
            </ng-container>

            <ng-container matColumnDef="download">
                <th mat-header-cell *matHeaderCellDef></th>
                <td mat-cell *matCellDef="let element"> <button mat-stroked-button (click)="export(element.link)"><mat-icon>download</mat-icon>Download</button>  </td>
            </ng-container>
        
            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let element; columns: displayedColumns;"></tr>
 
            

            

            <!-- This rows are beeing shown when we don't get any data from the server -->
            <tr class="mat-row" *matNoDataRow>
                <td *ngIf="input.value" class="mat-cell" colspan="4">{{localisation.content.app_nothingfoundfor}} "{{input.value}}"</td>
                <td *ngIf="!input.value" class="mat-cell" colspan="4">{{localisation.content.app_nothingfound}}!</td>
            </tr>
        </table>
      
        <mat-paginator [length]="resultsLength" [pageSizeOptions]="[20]"></mat-paginator>
      </div>
</div>
