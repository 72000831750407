<div class="updateform">
    <mat-card appearance="outlined" class="passwordfield">
        <div class="loading-shade" *ngIf="isLoading">
            <mat-spinner *ngIf="isLoading"></mat-spinner>
          </div>
        <div class="formheader">
            <img class="avatar" src="assets/img/avatar.svg">
        </div>
        <h2>Update Password:</h2>

        <form [formGroup]="updateuser" (submit)="submitUser()">

            <mat-form-field class="textinput" appearance="fill">
                <mat-label>New Passwort: </mat-label>
                <input [type]="hide ? 'password' : 'text'" matInput formControlName="p">   
                <button type="button" mat-icon-button matSuffix (click)="hide = !hide; $event.preventDefault()" [attr.aria-pressed]="hide">
                    <mat-icon>{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
                </button> 
                <mat-error>Min. 8 Max. 30</mat-error>
            </mat-form-field>

            <br /><br />
            <button type="submit" mat-flat-button color="primary"><mat-icon>save</mat-icon>Save</button>

        </form>
    </mat-card>
</div>
