import { AfterViewInit, Component, OnInit } from '@angular/core';
import { map } from 'rxjs/operators';
import { Breakpoints, BreakpointObserver } from '@angular/cdk/layout';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { LocalisationService } from '../localisation.service'

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements AfterViewInit {
  /** Based on the screen size, switch from standard to one column per row 
  cards = this.breakpointObserver.observe(Breakpoints.Handset).pipe(
    map(({ matches }) => {
      if (matches) {
        return [
          { title: 'My current Jobs', content: "dasd", cols: 2, rows: 1 }
          /*{ title: 'Card 2', cols: 2, rows: 1 },
          { title: 'Card 3', cols: 2, rows: 1 },
          { title: 'Card 4', cols: 2, rows: 1 }
        ];
      }

      return [
        { title: 'My current Jobss', content: "dasd", cols: 2, rows: 1 }
        /*{ title: 'Card 2', cols: 1, rows: 1 },
        { title: 'Card 3', cols: 1, rows: 2 },
        { title: 'Card 4', cols: 1, rows: 1 }
      ];
    })
  );*/

  constructor(private breakpointObserver: BreakpointObserver, private http:HttpClient, private router: Router, public localisation:LocalisationService) {}

  dataSource:any = [];
  displayedColumns:any = ["No", "Description", "Manager"];
  innerDisplayedColumns = ["No_", "company"];
  isLoading = true;


  ngAfterViewInit() {
    localStorage.setItem("lastPage", "/home");
    
    setTimeout(() => {
      this.http.get("/api/v1/active-jobs", {observe : 'response'}).subscribe(
        data => {
          this.dataSource = data.body
          this.isLoading = false;
       
        }, error => {
          this.isLoading = false;
          if(error.status == "403") {
            console.log("Logged out. Refreshing app...")
            window.location.reload();
          }
         
        }
      )
    },0)

  }

  newEntry(job: string, company: string, manager: string) {
    localStorage.setItem("newjob", job)
    //localStorage.setItem("newpurchase", purchase)
    localStorage.setItem("newcompany", company)
    localStorage.setItem("newman", manager)

    this.router.navigate(["/home/add"])
  }

}
