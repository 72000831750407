<img class="wave" src="assets/img/wave.png">
<div class="container">
  <div class="img">
    <img src="assets/img/bg.svg">
  </div>
  <div class="login-content">
    <div class="loading-shade" *ngIf="isLoggingIn">
      <mat-spinner *ngIf="isLoggingIn"></mat-spinner>
    </div>
    <form (submit)="loginUser($event)">
      <img class="avatar" src="assets/img/avatar.svg">
      <img class="login-logo" src="assets/img/logo.svg" />
      <h2 class="title">{{ localisation.content.app_title }}</h2>
      <h3>{{ localisation.content.login_header }}</h3>
      <div class="input-div one">
        <div class="i">
          <i class="fas fa-user"></i>
        </div>
        <mat-form-field>
          <mat-label class="input-label">E-Mail</mat-label>
          <input name="l-mail" id="l-mail" type="email" matInput autofocus placeholder="Ex. pat@example.com" required>
        </mat-form-field>
      </div>
     
      <div class="input-div pass">
        <div class="i">
          <i class="fas fa-lock"></i>
        </div>
        <mat-form-field>
          <mat-label class="input-label">Password</mat-label>
          <input name="l-pw" id="l-pw" matInput type="password" required>
        </mat-form-field>
      </div>
      <a (click)="passwordlost()">{{ localisation.content.login_lostpw }}</a>
      <input type="submit" class="btn" [value]="localisation.content.login_submit">
    </form>
  </div>

</div>

