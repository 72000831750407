<h2 mat-dialog-title >Edit Report {{this.data.ID}}</h2>

<mat-dialog-content [formGroup]="report">
    <form (submit)="update($event)">
        <mat-form-field class="textinput" appearance="fill">
            <mat-label>Project</mat-label>
            <input type="text" matInput formControlName="job" >
        </mat-form-field>

        <mat-form-field appearance="fill" class="textinput">
            <mat-label>{{localisation.content.entry_weather}}</mat-label>
            <mat-select required name="weather" id="weather" formControlName="weather" >
                <mat-option>--</mat-option>
                <mat-option *ngFor="let weather of weathers" [value]="weather.ID" >
                    {{weather.name}}  
                </mat-option>
            </mat-select>
            <mat-icon matSuffix>thermostat</mat-icon>
            <mat-error>{{localisation.content.app_invalidinput}}</mat-error>
        </mat-form-field>

        <mat-form-field class="textinput" appearance="fill">
            <mat-label>{{localisation.content.entry_temperature}}</mat-label>
            <input required name="temperature" id="temperature" type="number" matInput formControlName="temperature" >
            <span matSuffix>°C</span>
            <mat-error>{{localisation.content.app_invalidinput}}</mat-error>
        </mat-form-field>

        <mat-form-field appearance="fill" class="textinput">
            <mat-label>{{localisation.content.entry_work}}</mat-label>
            <mat-select required name="work" id="work" formControlName="work" >
            <mat-option>--</mat-option>
            <mat-option *ngFor="let work of works" [value]="work.ID" >
                {{work.name}}
            </mat-option>
            </mat-select>
            <mat-icon matSuffix>engineering</mat-icon>
            <mat-error>{{localisation.content.app_invalidinput}}</mat-error>
        </mat-form-field>

        <mat-form-field appearance="fill" class="textinput">
            <mat-label>{{localisation.content.entry_date}}</mat-label>
            <input matInput [matDatepicker]="picker" formControlName="date">
            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
            <mat-datepicker #picker></mat-datepicker>
            <mat-error>{{localisation.content.app_invalidinput}}</mat-error>
        </mat-form-field>

        <mat-form-field class="textinput" appearance="fill">
            <mat-label>{{localisation.content.entry_workerq}}</mat-label>
            <input required name="worker_quantity" id="worker_quantity" type="number" matInput formControlName="workquantity" >
            <mat-icon matSuffix>group</mat-icon>
            <mat-error>{{localisation.content.app_invalidinput}}</mat-error>
        </mat-form-field>

        <mat-form-field class="textinput" appearance="fill">
            <mat-label>{{localisation.content.entry_worktime}}</mat-label>
            <input required name="worker_time" id="worker_time" type="number" matInput formControlName="worktime" >
            <mat-icon matSuffix>schedule</mat-icon>
            <mat-error>{{localisation.content.app_invalidinput}}</mat-error>
        </mat-form-field>

        <mat-form-field class="textinput" appearance="fill">
            <mat-label>{{localisation.content.entry_forkliftquantity}}</mat-label>
            <input required name="forkliftquantity" id="forkliftquantity" type="number" matInput formControlName="forkliftquantity" >
            <mat-error>{{localisation.content.app_invalidinput}}</mat-error>
        </mat-form-field>

        <mat-form-field class="textinput" appearance="fill">
            <mat-label>{{localisation.content.entry_excavatorquantity}}</mat-label>
            <input required name="excavatorquantity" id="excavatorquantity" type="number" matInput formControlName="excavatorquantity" >
            <mat-error>{{localisation.content.app_invalidinput}}</mat-error>
        </mat-form-field>

        <mat-form-field class="textinput" appearance="fill">
            <mat-label>{{localisation.content.entry_workingplatformquantity}}</mat-label>
            <input required name="workingplatformquantity" id="workingplatformquantity" type="number" matInput formControlName="workingplatformquantity" >
            <mat-error>{{localisation.content.app_invalidinput}}</mat-error>
        </mat-form-field>

        <mat-form-field class="textinput" appearance="fill">
            <mat-label>{{localisation.content.entry_cranequantity}}</mat-label>
            <input required name="cranequantity" id="cranequantity" type="number" matInput formControlName="cranequantity" >
            <mat-error>{{localisation.content.app_invalidinput}}</mat-error>
        </mat-form-field>

        <mat-form-field appearance="fill" class="textinput">
            <mat-label>{{localisation.content.entry_perfwork}}</mat-label>
            <mat-select  required name="performed_work" id="performed_work" formControlName="performedwork" multiple >
            <mat-option *ngFor="let pwork of pworks" [value]="pwork.ID" >
                {{pwork.name}}
            </mat-option>
            </mat-select>
            <mat-icon matSuffix>handyman</mat-icon>
            <mat-error>{{localisation.content.app_invalidinput}}</mat-error>
        </mat-form-field>

        <mat-form-field class="textinput" appearance="fill">
            <mat-label>{{localisation.content.entry_deliveryq}}</mat-label>
            <input #deliveryquantity required name="deliveries_quantity" id="deliveries_quantity" type="number" matInput formControlName="deliveryquantity" >
            <mat-icon matSuffix>local_shipping</mat-icon>
            <mat-error>{{localisation.content.app_invalidinput}}</mat-error>
        </mat-form-field>

        <mat-form-field class="textinput" appearance="fill">
            <mat-label>{{localisation.content.entry_deliverycom}}</mat-label>
            <textarea #deliverycomments required class="textarea" matInput placeholder="Ex. It makes me feel..." formControlName="deliverycomments"></textarea>
            <mat-hint>{{deliverycomments.value.length}} / 500</mat-hint>
            <mat-error>{{localisation.content.app_invalidinput}}</mat-error>
        </mat-form-field>

        <mat-form-field class="textinput" appearance="fill">
            <mat-label>{{localisation.content.entry_perfworkcom}}</mat-label>
            <textarea #performedworkcomments required class="textarea" matInput placeholder="Ex. It makes me feel..." formControlName="performedworkcomments"></textarea>
            <mat-hint>{{performedworkcomments.value.length}} / 500</mat-hint>
            <mat-error>{{localisation.content.app_invalidinput}}</mat-error>
        </mat-form-field>

        <mat-form-field class="textinput" appearance="fill">
            <mat-label>{{localisation.content.entry_othercom}}</mat-label>
            <textarea #othercomment required class="textarea" matInput placeholder="Ex. It makes me feel..." formControlName="othercomments"></textarea>
            <mat-hint>{{othercomment.value.length}} / 500</mat-hint>
            <mat-error>{{localisation.content.app_invalidinput}}</mat-error>
        </mat-form-field>

        <br /><br />

        <button type="submit" mat-fab color="primary" class="form-fab-submit" ><mat-icon>save</mat-icon></button>
        <button type="submit" mat-flat-button color="primary" class="form-button-submit"><mat-icon>save</mat-icon>{{localisation.content.app_save}}</button>
    </form>
</mat-dialog-content>
