import { Component, OnInit, Inject, AfterViewInit, ViewChild } from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog'
import { FormArray, UntypedFormControl, UntypedFormGroup, ReactiveFormsModule, Validators  } from '@angular/forms';
import { LoadingshadeService } from '../loadingshade.service'
import { LocalisationService } from '../localisation.service'
import { HttpClient, HttpHeaders } from '@angular/common/http';
import {NotifierService} from '../notifier.service'
import { GetDataService } from '../get-data.service';
//import * as moment from 'moment';

var stringPattern = '^([A-Z,a-z,0-9,Ä,Ö,Ü,ä,ö,ü,À,Ò,Ù,à,ò,ù,Á,Ó,Ú,á,ó,ú,´,-,?,.,!,/,°,+,*,#,§,$,%,&,(,),=,~, ,@,-\:,\n]{1,500})$';


@Component({
  selector: 'app-dialog-report',
  templateUrl: './dialog-report.component.html',
  styleUrls: ['./dialog-report.component.css']
})
export class DialogReportComponent implements OnInit {

  constructor(public nav:LoadingshadeService ,public localisation:LocalisationService, public dialog: MatDialogRef<DialogReportComponent>,@Inject(MAT_DIALOG_DATA) public data:any, private http: HttpClient, private notifier: NotifierService, private getData: GetDataService) { }
  
  report = new UntypedFormGroup({
    ID: new UntypedFormControl(this.data.ID, Validators.compose([Validators.pattern(stringPattern), Validators.required])),
    job: new UntypedFormControl({value: this.data.job, disabled: true}, Validators.compose([Validators.pattern(stringPattern), Validators.required])),
    weather: new UntypedFormControl('', Validators.compose([Validators.min(1), Validators.max(999)])),
    temperature: new UntypedFormControl(0,  Validators.compose([Validators.min(-50), Validators.max(50)])),
    work: new UntypedFormControl('', Validators.compose([Validators.min(1), Validators.max(999)])),
    date: new UntypedFormControl(new Date(), [Validators.required]),
    workquantity: new UntypedFormControl(1, Validators.compose([Validators.min(0), Validators.max(999)])),
    worktime: new UntypedFormControl('', Validators.compose([Validators.min(1), Validators.max(999)])),
    forkliftquantity: new UntypedFormControl(0, Validators.compose([Validators.min(0), Validators.max(999)])),
    excavatorquantity: new UntypedFormControl(0, Validators.compose([Validators.min(0), Validators.max(999)])),
    workingplatformquantity: new UntypedFormControl(0, Validators.compose([Validators.min(0), Validators.max(999)])),
    cranequantity: new UntypedFormControl(0, Validators.compose([Validators.min(0), Validators.max(999)])),
    performedwork: new UntypedFormControl('', Validators.compose([Validators.min(1), Validators.max(999)])),
    deliveryquantity: new UntypedFormControl(0,Validators.compose([Validators.min(0), Validators.max(999)])), 
    deliverycomments: new UntypedFormControl('', Validators.compose([Validators.pattern(stringPattern)])),
    performedworkcomments: new UntypedFormControl('', Validators.compose([Validators.pattern(stringPattern), Validators.required])),
    othercomments: new UntypedFormControl('', Validators.compose([Validators.pattern(stringPattern), Validators.required])),
  })

  // stuff for getting weather from api
  weathers:any = [];

  // stuff for gettings work types
  works:any = [];

  // stuff for getting performed works
  pworks:any = [];

  ngOnInit(): void {
    this.getData.logentry(this.data.ID).subscribe(data => {  
      this.report.get("job")?.setValue(data.job)  
      //this.newentry.get("purchase")?.setValue(data.purchase)     
      this.report.get("weather")?.setValue(data.weather)
      this.report.get("temperature")?.setValue(data.temperature)
      this.report.get("work")?.setValue(data.work)
      this.report.get("workquantity")?.setValue(data.worker_quantity)
      this.report.get("worktime")?.setValue(data.work_time)
      this.report.get("forkliftquantity")?.setValue(data.forkliftquantity)
      this.report.get("excavatorquantity")?.setValue(data.excavatorquantity)
      this.report.get("workingplatformquantity")?.setValue(data.workingplatformquantity)
      this.report.get("cranequantity")?.setValue(data.cranequantity)
      this.report.get("performedwork")?.setValue(data.performed_work)
      this.report.get("deliveryquantity")?.setValue(data.deliveries_quantity)
      this.report.get("deliverycomments")?.setValue(data.deliveries_comments)
      this.report.get("performedworkcomments")?.setValue(data.performed_works_comments)
      this.report.get("othercomments")?.setValue(data.other_comments)

    }, error => {
      this.notifier.showNotification("Error while getting data from Server! Please log in.", "error");
    })


    this.getData.weather().subscribe(data => {
      this.weathers = data.body
    }, error => {
      this.notifier.showNotification("Error while getting data from Server! Please log in.", "error");
      if(error.status == "403") {
        console.log("Logged out. Refreshing app...")
        window.location.reload();
      }
    })

    this.getData.work().subscribe(data => {
      this.works = data
    }, error => {
      this.notifier.showNotification("Error while getting data from Server! Please log in.", "error");
    })

    this.getData.performed_work().subscribe(data => {
      this.pworks = data
      this.nav.isLoadingResults = false;
    }, error => {
      this.notifier.showNotification("Error while getting data from Server! Please log in.", "error");
      this.nav.isLoadingResults = false;
    })

    
  }

  update(event: any) {
    if(this.report.valid) {

      let datatopost = JSON.stringify(this.report.getRawValue(), (key: any, value: any) => {
        if (JSON.parse(JSON.stringify((value))) === value) {
          return (value).format("yyyy-MM-DD");
        } else {
          return value;
        }
      });

      const httpOptions = {
        headers: new HttpHeaders({
          'Content-Type':  'application/json',
          'observe' : 'response'
        })
      };

      this.http.put<any>("/api/v1/logentry", datatopost, httpOptions).subscribe(
        data => {
          this.notifier.showNotification(data.status, "success")
          this.dialog.close()

        }, error => {
          this.notifier.showNotification(error.error, "error")
        }
      )

    }

  }

}
