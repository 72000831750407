import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of, Subscription } from 'rxjs';
import { tap, startWith, debounceTime, distinctUntilChanged, switchMap, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class GetDataService {

  constructor(private http: HttpClient) { }

  opts = [];
  optspurch =  []

  jobs(search:string) {
    return this.http.get<any>('/api/v1/job?s='+search)
  }

  jobDetails(j: string) {
    return this.http.get<any>('/api/v1/job/' + j)
  }

  purchases(p: string) {
    return this.http.get<any>('/api/v1/purchase/' + p).pipe(tap(dataP => this.optspurch = dataP))
  }

  weather() {
    return this.http.get<any>('/api/v1/weather', {observe : 'response'})
  }

  work() {
    return this.http.get<any>('/api/v1/work')
  }

  performed_work() {
    return this.http.get<any>('/api/v1/performed-work')
  }

  logentry(p:string) {
    return this.http.get<any>('/api/v1/logentry/'+p)
  }

}
