import { HttpClient } from '@angular/common/http';
import {AfterViewInit, Component, ViewChild} from '@angular/core';
import {MatPaginator} from '@angular/material/paginator';
import {MatSort} from '@angular/material/sort';
import {merge, Observable, of as observableOf} from 'rxjs';
import { startWith, switchMap, map, catchError } from 'rxjs/operators';
import {animate, state, style, transition, trigger} from '@angular/animations';

import { Logentry } from '../logentry'
import { NotifierService } from '../notifier.service'
import { LoadingshadeService } from '../loadingshade.service'
import { LocalisationService } from '../localisation.service'
import { Router } from '@angular/router'
import { UserData } from '../userdata';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { DialogReportComponent } from '../dialog-report/dialog-report.component';

@Component({
  selector: 'app-allentries',
  templateUrl: './allentries.component.html',
  styleUrls: ['./allentries.component.css'],
  // used for expandable table row
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({height: '0px', minHeight: '0'})),
      state('expanded', style({height: '*'})),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})
export class AllentriesComponent implements AfterViewInit {

  displayedColumns: string[] = ['reportID', 'job', 'date', 'work', 'createdBy'];
  dataSource!: Observable<Logentry[]>;
  resultsLength = 0;
  expandedElement!: Logentry;
  files:any = [];
  lastID!:number;


  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;

  constructor(
    private http: HttpClient, 
    private notifier: NotifierService, 
    public nav:LoadingshadeService, 
    public localisation:LocalisationService, 
    public router: Router, 
    public user: UserData,
    private dialog: MatDialog) { }

  ngAfterViewInit() {
    setTimeout(() => { // resolves error "NG0100: Expression has changed after it was checked" https://angular.io/errors/NG0100
      this.nav.isLoadingResults = true;
      localStorage.setItem("lastPage", "/home/all");
    },0)

    this.getReports();
  }

  resetPaging() {
    this.paginator.pageIndex = 0;
  }

  applyFilter(event: Event) {
    this.nav.isLoadingResults = true;
    const filterValue = (event.target as HTMLInputElement).value;

    this.dataSource = merge(this.sort.sortChange, this.paginator.page).pipe(
      startWith({}),
        switchMap(() => {
          this.nav.isLoadingResults = true;
          return this.getEntries(filterValue, this.sort.active, this.sort.direction, this.paginator.pageIndex, this.paginator.pageSize);
        }),
        map(data => {
          // Flip flag to show that loading has finished.
          this.nav.isLoadingResults = false;       
          return data.body.entries;
        }),
        catchError((data) => {
          this.nav.isLoadingResults = false;
          if(data.status == "403") {
            console.log("Logged out. Refreshing app...")
            window.location.reload();
          }
          return observableOf([]);
        })
    )
  
    this.paginator.firstPage();
    
  }

  getFiles(id:number) {
    if(id != this.lastID) {
      this.files = [];
      this.lastID = id

      this.http.get<any>("/api/v1/file/"+id, {observe : 'response'}).subscribe(
        data => {        
            this.files = data.body              
        }, error => {
          if(error.status == "403") {
            console.log("Logged out. Refreshing app...")
            window.location.reload();
          }
        }
      )
    } 
  }

  openAsWindow(url: string){
    window.open(url,'File');  
  }


  getEntries(search:string, sort: string, order: string, page: number, limit: number): Observable<any> {
    page++;
    return this.http.get<any>("/api/v1/logentry?search="+search+"&sort="+sort+"&order="+order+"&page="+ page+"&limit="+limit, {observe : 'response'});
  }

  clone(el: Object){
    localStorage.setItem("clone", JSON.stringify(el))
    this.router.navigate(["/home/add"])
    
  }

  delete(el:any) {
   var d = confirm("Are you sure?")
   if(d) {
    this.http.delete<any>("/api/v1/logentry/" + el.ID).subscribe(data => {
      this.notifier.showNotification("Report removed", "success")
      this.getReports();
    }, error => {
      this.notifier.showNotification(error.error, "error")
    })
   }

  }

  openReport(report: any): void {
    let dialogref = this.dialog.open(DialogReportComponent, {
      data: report
    });

    dialogref.afterClosed().subscribe(result => {
      this.getReports();
    })

    
  }

  getReports() {
    setTimeout(() => { // resolves error "NG0100: Expression has changed after it was checked" https://angular.io/errors/NG0100
      this.dataSource = merge(this.sort.sortChange, this.paginator.page).pipe(
        startWith({}),
          switchMap(() => {
            return this.getEntries("", this.sort.active, this.sort.direction, this.paginator.pageIndex, this.paginator.pageSize);
          }),
          map(data => {
            // Flip flag to show that loading has finished.
            this.nav.isLoadingResults = false;
            this.resultsLength = data.body.count.total;
            if(!data.entries) {
              //this.notifier.showNotification("Error while getting data from the server! Please log in.", "error");
            }
            return data.body.entries;
          }),
          catchError((data) => {
            this.nav.isLoadingResults = false;
            if(data.status == "403") {
              console.log("Logged out. Refreshing app...")
              window.location.reload();
            }
            return observableOf([]);
          })
      )
    }, 0)
  }

}
