import { AfterViewInit, Component, NgModule, OnInit, ViewChild } from '@angular/core';

import { FormArray, UntypedFormControl, UntypedFormGroup, ReactiveFormsModule, Validators  } from '@angular/forms';
import { Observable } from 'rxjs';
import { map, startWith, debounceTime, distinctUntilChanged, switchMap } from 'rxjs/operators';
import { GetDataService } from '../get-data.service';
import { NotifierService } from '../notifier.service';
import { HttpClient, HttpEventType, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Router } from '@angular/router'
import {FilehandlerService} from '../filehandler.service'
import { LoadingshadeService } from '../loadingshade.service'
import { LocalisationService } from '../localisation.service'
//import * as moment from 'moment';

var stringPattern = '^([A-Z,a-z,0-9,Ä,Ö,Ü,ä,ö,ü,À,Ò,Ù,à,ò,ù,Á,Ó,Ú,á,ó,ú,è,é,´,`,^,-,?,ß,.,!,/,°,|,+,*,#,§,$,%,&,(,),=,~, ,@,-\:,\;,_,\n]{1,500})$';

@Component({
  selector: 'app-addentry',
  templateUrl: './addentry.component.html',
  styleUrls: ['./addentry.component.css']
})
export class AddentryComponent implements AfterViewInit {

  constructor(private getData: GetDataService, 
    private notifier: NotifierService, 
    private http: HttpClient, 
    private router: Router, 
    private fileservice: FilehandlerService, 
    public nav: LoadingshadeService,
    public localisation:LocalisationService) { }

  person_responsible = "";
  
  newentry = new UntypedFormGroup({
    job: new UntypedFormControl('', Validators.compose([Validators.pattern(stringPattern), Validators.required])),
    //purchase: new FormControl('', Validators.compose([Validators.pattern(stringPattern), Validators.required])),
    weather: new UntypedFormControl('', Validators.compose([Validators.min(1), Validators.max(999)])),
    temperature: new UntypedFormControl('',  Validators.compose([Validators.min(-50), Validators.max(50)])),
    work: new UntypedFormControl('', Validators.compose([Validators.min(1), Validators.max(999)])),
    date: new UntypedFormControl(new Date(), [Validators.required]),
    workquantity: new UntypedFormControl(1, Validators.compose([Validators.min(0), Validators.max(999)])),
    worktime: new UntypedFormControl('', Validators.compose([Validators.min(1), Validators.max(999)])),
    forkliftquantity: new UntypedFormControl(0, Validators.compose([Validators.min(0), Validators.max(999)])),
    excavatorquantity: new UntypedFormControl(0, Validators.compose([Validators.min(0), Validators.max(999)])),
    workingplatformquantity: new UntypedFormControl(0, Validators.compose([Validators.min(0), Validators.max(999)])),
    cranequantity: new UntypedFormControl(0, Validators.compose([Validators.min(0), Validators.max(999)])),
    performedwork: new UntypedFormControl('', Validators.compose([Validators.min(1), Validators.max(999)])),
    deliveryquantity: new UntypedFormControl(0,Validators.compose([Validators.min(0), Validators.max(999)])), // TODO: wen größer 0 pflichtfeld
    deliverycomments: new UntypedFormControl('', Validators.compose([Validators.pattern(stringPattern)])),
    performedworkcomments: new UntypedFormControl('', Validators.compose([Validators.pattern(stringPattern), Validators.required])),
    othercomments: new UntypedFormControl('', Validators.compose([Validators.pattern(stringPattern), Validators.required])),
  })


  // stuff for getting jobs from api
  filteredJobs?: Observable<any[]>;
  
  // stuff for getting purchases from api
  filteredPurchases?: Observable<any[]>;

  // stuff for getting weather from api
  weathers:any = [];

  // stuff for gettings work types
  works:any = [];

  // stuff for getting performed works
  pworks:any = [];

  // START File upload code
  selectedFiles!: FileList;
  progressInfos:any = [];
  message = '';

  fileInfos!: Observable<any>;

  selectFiles(event:any) {
    this.progressInfos = [];
    this.selectedFiles = event.target.files;
  }

  uploadFiles(entrienr:number) {
    this.message = '';
  
    if(this.selectedFiles) {
      for (let i = 0; i < this.selectedFiles.length; i++) {
        this.upload(i, this.selectedFiles[i], entrienr);
      }
    }
  }

  upload(idx:any, file:File, entrienr:number) {
    this.progressInfos[idx] = { value: 0, fileName: file.name };
  
    this.fileservice.upload(file, entrienr)
  }

  // END File upload code

  toclone:any
  
  ngAfterViewInit() {    
    localStorage.setItem("lastPage", "/home/add");
    
    setTimeout(() => {
      this.nav.isLoadingResults = true;
      
      this.refreshJobs();

      this.newentry.get("job")?.setValue(localStorage.getItem("newjob"))
      
      /*if(localStorage.getItem("newcompany") && localStorage.getItem("newpurchase")) {
        this.newentry.get("purchase")?.setValue(localStorage.getItem("newcompany") + "-" + localStorage.getItem("newpurchase"))
      }*/

      if(localStorage.getItem("clone")) {

        //this.newentry.reset();
        
        this.toclone = JSON.parse(localStorage.getItem("clone") || "")
        localStorage.removeItem("clone")

        this.getData.logentry(this.toclone.ID).subscribe(data => {  
          this.newentry.get("job")?.setValue(data.job)  
          //this.newentry.get("purchase")?.setValue(data.purchase)     
          this.newentry.get("weather")?.setValue(data.weather)
          this.newentry.get("temperature")?.setValue(data.temperature)
          this.newentry.get("work")?.setValue(data.work)
          this.newentry.get("workquantity")?.setValue(data.worker_quantity)
          this.newentry.get("worktime")?.setValue(data.work_time)
          this.newentry.get("forkliftquantity")?.setValue(data.forkliftquantity)
          this.newentry.get("excavatorquantity")?.setValue(data.excavatorquantity)
          this.newentry.get("workingplatformquantity")?.setValue(data.workingplatformquantity)
          this.newentry.get("cranequantity")?.setValue(data.cranequantity)
          this.newentry.get("performedwork")?.setValue(data.performed_work)
          this.newentry.get("deliveryquantity")?.setValue(data.deliveries_quantity)
          this.newentry.get("deliverycomments")?.setValue(data.deliveries_comments)
          this.newentry.get("performedworkcomments")?.setValue(data.performed_works_comments)
          this.newentry.get("othercomments")?.setValue(data.other_comments)

        }, error => {
          this.notifier.showNotification("Error while getting data from Server! Please log in.", "error");
        })

        

      }

      this.person_responsible = localStorage.getItem("newman") || ""
      localStorage.removeItem("newjob")
      localStorage.removeItem("newpurchase")
      localStorage.removeItem("newcompany")
      localStorage.removeItem("newman")
         
      this.getData.weather().subscribe(data => {
        this.weathers = data.body
      }, error => {
        this.notifier.showNotification("Error while getting data from Server! Please log in.", "error");
        if(error.status == "403") {
          console.log("Logged out. Refreshing app...")
          window.location.reload();
        }
      })

      this.getData.work().subscribe(data => {
        this.works = data
      }, error => {
        this.notifier.showNotification("Error while getting data from Server! Please log in.", "error");
      })

      this.getData.performed_work().subscribe(data => {
        this.pworks = data
        this.nav.isLoadingResults = false;
      }, error => {
        this.notifier.showNotification("Error while getting data from Server! Please log in.", "error");
        this.nav.isLoadingResults = false;
      })

      //this.fileInfos = this.fileservice.getFiles();
    },0)
  }



  public refreshJobs() {
    this.filteredJobs = this.getData.jobs(this.newentry.get("job")?.value)

    // validators = incorrect if user is typing
    this.newentry.get("job")?.setErrors({'incorrect': true})
  }



  public setPersonResponsible(p: string){
    this.person_responsible = p
  }

 
  public createEntry(event: any) {
    event.preventDefault(); // disable page refresh

    if(this.newentry.valid) {
      this.nav.isLoadingResults = true;
      //let datatopost = JSON.stringify(this.newentry.getRawValue())

      let datatopost = JSON.stringify(this.newentry.getRawValue(), (key: any, value: any) => {
        return value;
        /*if (JSON.parse(JSON.stringify((value))) === value) {
          return (value).format("yyyy-MM-DD");
        } else {
          return value;
        }*/
      });

      const httpOptions = {
        headers: new HttpHeaders({
          'Content-Type':  'application/json'
        })
      };

       this.http.post<any>('/api/v1/logentry', datatopost, httpOptions).subscribe(
        data => {          
          this.nav.isLoadingResults = false;
          this.uploadFiles(data.ID);
          //console.log(data)

          this.notifier.showNotification("Entry created!", "success");
          this.router.navigate(["/home/my"]);

          
        }, error => {
          this.nav.isLoadingResults = false;
          this.notifier.showNotification("Error while posting data to the server! Please log in.", "error");
          console.log(error)
        })
      
    } 
  }




}
