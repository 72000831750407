<h2 mat-dialog-title>Password reset</h2>

<mat-dialog-content>
    <form [formGroup]="passwordreset" (submit)="submit()">

        <mat-form-field class="textinput" appearance="fill" >
            <mat-label>E-Mail:</mat-label>
            <input type="email" matInput formControlName="email"  >
        </mat-form-field>

        <br />
        <button type="submit" mat-raised-button color="primary" >Send password reset link</button> 
        <button type="button" mat-dialog-close mat-button>Cancel</button>

    </form>
</mat-dialog-content>