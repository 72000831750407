import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { HttpClient } from '@angular/common/http';
import { AfterViewInit, Component, Inject, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { map } from 'rxjs/operators';
import { LoadingshadeService } from '../loadingshade.service';
import { LocalisationService } from '../localisation.service';
import { NotifierService } from '../notifier.service';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { DialogUserComponent } from '../dialog-user/dialog-user.component';
import { Observable } from 'rxjs';



@Component({
  selector: 'app-manage',
  templateUrl: './manage.component.html',
  styleUrls: ['./manage.component.css']
})
export class ManageComponent implements AfterViewInit {
  isLoadingResults: boolean = true;
  
  userdata!:Observable<any>
  displayedColumns_users: string[] = ['name', 'email', 'company', 'actions'];

  constructor(private http: HttpClient, private notifier: NotifierService, public nav:LoadingshadeService, public localisation:LocalisationService, public router: Router, public dialog: MatDialog) { }

  ngAfterViewInit(): void {   
    localStorage.setItem("lastPage", "/home/manage");
    this.getUsers();
  }


  getUsers() {
    this.isLoadingResults = true
    this.http.get<any>('/api/v1/user', {observe : 'response'}).subscribe( data =>  {
      this.userdata = data.body
      this.isLoadingResults = false
    }, error => {
      this.isLoadingResults = false
      if(error.status == "403") {
        console.log("Logged out. Refreshing app...")
        window.location.reload();
      }
    }) 
  }

  SearchUsers(event:Event) {
    const filterValue = (event.target as HTMLInputElement).value;

      this.isLoadingResults = true

      this.http.get<any>('/api/v1/user?s='+filterValue, {observe : 'response'}).subscribe( data =>  {
        this.userdata = data.body
        this.isLoadingResults = false
      }, error => {
        this.isLoadingResults = false
        if(error.status == "403") {
          console.log("Logged out. Refreshing app...")
          window.location.reload();
        } else if(error.status == "404") {
          this.userdata = error.body
        }
      }) 
    
    
  }

  openUser(user: any, input: any): void {
    let dialogref = this.dialog.open(DialogUserComponent, {
      data: user
    });

    dialogref.afterClosed().subscribe(result => {
      this.clear(input)
      this.getUsers()
    })

    
  }

  clear(input: HTMLInputElement) {
    input.value = '';

  }


}

