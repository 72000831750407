import { Component, OnInit, Inject, AfterViewInit, ViewChild } from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog'
import { FormArray, UntypedFormControl, UntypedFormGroup, ReactiveFormsModule, Validators  } from '@angular/forms';
import { LoadingshadeService } from '../loadingshade.service'
import { LocalisationService } from '../localisation.service'
import { HttpClient, HttpHeaders } from '@angular/common/http';
import {NotifierService} from '../notifier.service'


var stringPattern = '^([A-Z,a-z,0-9,Ä,Ö,Ü,ä,ö,ü,À,Ò,Ù,à,ò,ù,Á,Ó,Ú,á,ó,ú,´,-,?,.,!,/,°,+,*,#,§,$,%,&,(,),=,~, ,@,-\:,\n]{1,500})$';

@Component({
  selector: 'app-dialog-user',
  templateUrl: './dialog-user.component.html',
  styleUrls: ['./dialog-user.component.css']
})
export class DialogUserComponent implements OnInit {

  //@ViewChild(MatAccordion) accordion!: MatAccordion;

  constructor(public dialog: MatDialogRef<DialogUserComponent>,@Inject(MAT_DIALOG_DATA) public data:any, private http: HttpClient, private notifier: NotifierService) { }
  

  user = new UntypedFormGroup({
    ID: new UntypedFormControl(this.data.ID, Validators.compose([Validators.pattern(stringPattern)])),
    name: new UntypedFormControl(this.data.name, Validators.compose([Validators.pattern(stringPattern), Validators.required])),
    email: new UntypedFormControl(this.data.email, Validators.compose([Validators.email, Validators.required])),
    company: new UntypedFormControl(this.data.company, Validators.compose([Validators.pattern(stringPattern)])),
    role: new UntypedFormControl(this.data.role || 0, Validators.compose([Validators.min(0), Validators.max(10), Validators.required])),
    active: new UntypedFormControl(this.data.active || 0, Validators.compose([Validators.min(0), Validators.max(2), Validators.required])),
    language: new UntypedFormControl(this.data.language || 'de', Validators.compose([Validators.pattern('^(de|en|it)$'), Validators.required])),
    contact_id: new UntypedFormGroup({})
  })
  
  externaluser!:boolean
  companies!: any


  ngOnInit(): void {
    // check if user is external or new  
    if(this.data.ID) {
      if(this.data.ID.split('-', 2)[0] == "S") {
        this.externaluser = false
      } else {
        this.externaluser = true
      }
    } else {
      this.externaluser = true
    }

    this.http.get<any>("/api/v1/company", {observe : 'response'}).subscribe(data =>{
      this.companies = data.body      
      const group = this.user.get('contact_id') as UntypedFormGroup
     
      data.body.forEach((company: any) => {
        if(this.data.contact_id) {
          group.addControl(company.ID, new UntypedFormControl(this.data.contact_id[company.ID]))   
        } else {
          group.addControl(company.ID, new UntypedFormControl(''))
        }
      });

    }, error => {
      if(error.status == "403") {
        console.log("Logged out. Refreshing app...")
        window.location.reload();
      }
    })  
  }


  submit() {
    if(this.user.valid) { 
      let datatopost = JSON.stringify(this.user.getRawValue())

      const httpOptions = {
        headers: new HttpHeaders({
          'Content-Type':  'application/json',
          'observe' : 'response'
        })
      };
      
      if(this.data.ID) {
        // Edit user (PUT)
        this.http.put<any>('/api/v1/user', datatopost, httpOptions).subscribe(
          data => {       
            this.notifier.showNotification("User updated!", "success");
            this.dialog.close()
  
          }, error => {
            console.log(error)
            this.notifier.showNotification(error.error, "error");
          })

      } else {
        // Create User (POST)
        this.http.post<any>('/api/v1/user', datatopost, httpOptions).subscribe(
          data => {       
            this.notifier.showNotification("User created!", "success");
            this.dialog.close()
  
          }, error => {
            console.log(error)
            this.notifier.showNotification(error.error, "error");
          })

      }

    } 

  }

  cancel() {
    this.dialog.close()
  }

  resendMail(ID: string) {

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
        'observe' : 'response'
      })
    };

    this.http.patch<any>('/api/v1/user?resendmail=' + ID, httpOptions).subscribe(
      data => {
        this.notifier.showNotification(data.status, "success");
        this.dialog.close();
      }, error => {
        console.log(error)
        this.notifier.showNotification(error.error, "error");
      }
    )

  }

  passwordReset(mail: string) {

    let datatopost = JSON.stringify(['email', mail])

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
        'observe' : 'response'
      })
    };

    
    this.http.put<any>("/api/v1/recoverpassword", datatopost, httpOptions).subscribe(data => {
      this.notifier.showNotification("Mail send!", "success")
      this.dialog.close();
    }, error => {
      this.notifier.showNotification(error.error, "error")
    })
  }

}
