import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { UserData } from './userdata'
import { Router } from '@angular/router';


@Injectable({
  providedIn: 'root'
})
export class AuthService {

  constructor(private http : HttpClient, private user: UserData | null, private router: Router) { }

  login(email: string, password: string) {
    // returns User Info   
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
        //Authorization: 'Basic ' + btoa(email + ":" + password)
      })
    };
    return this.http.post<UserData>('/api/v1/self', { email, password } , httpOptions)
  }

  logout() {
    console.log("Bye!");
    localStorage.clear();
    this.user = null;
    this.http.get('/api/v1/logout').subscribe(data => {
      this.router.navigate(["/login"])
    })    
  }

}


