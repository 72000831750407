<mat-sidenav-container class="sidenav-container">
  <mat-sidenav #drawer class="sidenav" fixedInViewport
      [attr.role]="(isHandset$ | async) ? 'dialog' : 'navigation'"
      [mode]="(isHandset$ | async) ? 'over' : 'side'"
      [opened]="(isHandset$ | async) === false">
    <mat-toolbar>{{localisation.content.nav_header}}</mat-toolbar>
    <!-- Mobile -->
    <mat-nav-list *ngIf="isHandset$ | async">
      <a (click)="drawer.toggle()" mat-list-item routerLink="/home" ><i class="fas fa-home fa-nav"></i>{{localisation.content.nav_home}}</a>
      <a (click)="drawer.toggle()" mat-list-item routerLink="/home/add" ><i class="fas fa-plus fa-nav"></i>{{localisation.content.nav_newentry}}</a>
      <a (click)="drawer.toggle()" mat-list-item routerLink="/home/my" ><i class="fas fa-stream fa-nav"></i>{{localisation.content.nav_myentry}}</a>
      <a (click)="drawer.toggle()" mat-list-item routerLink="/home/all" *ngIf="user.role >= 5" ><i class="fas fa-book fa-nav"></i>{{localisation.content.nav_allentry}}</a>
      <a (click)="drawer.toggle()" mat-list-item routerLink="/home/export" *ngIf="user.role >= 5" ><i class="fas fa-download fa-nav"></i>Export</a>
      <a (click)="drawer.toggle()" mat-list-item routerLink="/home/manage" *ngIf="user.role >= 10" ><i class="fas fa-cogs fa-nav"></i>{{localisation.content.nav_manage}}</a>
      <a (click)="drawer.toggle()" mat-list-item href="https://tickets.rabensteiner.eu/help/" target="_blank" ><i class="fas fa-question-circle fa-nav"></i>{{localisation.content.nav_help}}</a>
      <a (click)="drawer.toggle()" mat-list-item (click)="auth.logout()" ><i class="fas fa-sign-out-alt fa-nav"></i>{{localisation.content.nav_logout}}</a>
    </mat-nav-list>
    <!-- Desktop -->
    <mat-nav-list *ngIf="!(isHandset$ | async)">
      <a mat-list-item routerLink="/home" ><i class="fas fa-home fa-nav"></i>{{localisation.content.nav_home}}</a>
      <a mat-list-item routerLink="/home/add" ><i class="fas fa-plus fa-nav"></i>{{localisation.content.nav_newentry}}</a>
      <a mat-list-item routerLink="/home/my" ><i class="fas fa-stream fa-nav"></i>{{localisation.content.nav_myentry}}</a>
      <a mat-list-item routerLink="/home/all" *ngIf="user.role >= 5" ><i class="fas fa-book fa-nav"></i>{{localisation.content.nav_allentry}}</a>
      <a mat-list-item routerLink="/home/export" *ngIf="user.role >= 5" ><i class="fas fa-download fa-nav"></i>Export</a>
      <a mat-list-item routerLink="/home/manage" *ngIf="user.role >= 10" ><i class="fas fa-cogs fa-nav"></i>{{localisation.content.nav_manage}}</a>
      <a mat-list-item href="https://tickets.rabensteiner.eu/help/" target="_blank" ><i class="fas fa-question-circle fa-nav"></i>{{localisation.content.nav_help}}</a>
      <a mat-list-item (click)="auth.logout()" ><i class="fas fa-sign-out-alt fa-nav"></i>{{localisation.content.nav_logout}}</a>
    </mat-nav-list>
    <div class="footer_logo"><img src="assets/img/logo.svg"></div>
  </mat-sidenav>
  <mat-sidenav-content>
    <mat-toolbar color="primary">
      <button
        type="button"
        aria-label="Toggle sidenav"
        mat-icon-button
        (click)="drawer.toggle()"
        *ngIf="isHandset$ | async">
        <mat-icon aria-label="Side nav toggle icon">menu</mat-icon>
      </button>
      <img class="app-logo" src="assets/img/hook.svg" width="50px" height="50px" style="margin-right: 10px; margin-left: 5px;" />
      <span class="app-name">{{localisation.content.app_title}}</span>
      <div class="loading-shade">
        <mat-spinner diameter="40" color="accent" *ngIf="nav.isUploadingFiles || nav.isLoadingResults"></mat-spinner>
        <span *ngIf="nav.isUploadingFiles">File Upload in progress...</span>
    </div>
    </mat-toolbar>

    <!-- Add Content Here -->

    <router-outlet></router-outlet>

  </mat-sidenav-content>
</mat-sidenav-container>
