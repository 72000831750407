<div class="grid-container">
  <h1 class="mat-h1">{{localisation.content.nav_home}}</h1>
         
    <table mat-table multiTemplateDataRows  [dataSource]="dataSource">
      <!-- Position Column -->
      <ng-container matColumnDef="No">
        <th mat-header-cell *matHeaderCellDef>{{localisation.content.entry_jobno}}</th>
        <td mat-cell *matCellDef="let element"> {{element.No_}} </td>
      </ng-container>
    
      <!-- Name Column -->
      <ng-container matColumnDef="Description">
        <th mat-header-cell *matHeaderCellDef>{{localisation.content.entry_jobdesc}}</th>
        <td mat-cell *matCellDef="let element"> {{element.Description}} </td>
      </ng-container>

      <!-- Name Column -->
      <ng-container matColumnDef="Manager">
        <th mat-header-cell *matHeaderCellDef>{{localisation.content.entry_jobmanager}}</th>
        <td mat-cell *matCellDef="let element"> {{element.person_responsible}} </td>
      </ng-container>
   
      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row2; columns: displayedColumns;" class="rowjob" title="Create new report" (click)="newEntry(row2.No_, row2.company, row2.person_responsible)"></tr>
      <!--<tr mat-row *matRowDef="let element2; columns: ['expandedDetail']"></tr>-->

      <!--<ng-container matColumnDef="expandedDetail">
        <td mat-cell *matCellDef="let element2" [attr.colspan]="displayedColumns.length" >
          <div class="detail-row">
            <div class="detail-row-content">
              <p *ngIf="!element2.purchases">No purchases found!</p>
              
              <table mat-table [dataSource]="element2.purchases" class="innertable" *ngIf="element2.purchases">
                
                <ng-container matColumnDef="No_" >
                  <th mat-header-cell *matHeaderCellDef>{{localisation.content.entry_orderno}}</th>
                  <td mat-cell *matCellDef="let element"> {{element.No_}} </td>
                </ng-container>

                <ng-container matColumnDef="company" >
                  <th mat-header-cell *matHeaderCellDef>{{localisation.content.entry_ordercompany}}</th>
                  <td mat-cell *matCellDef="let element"> {{element.company}} </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="innerDisplayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: innerDisplayedColumns;" title="Create new report" (click)="newEntry(element2.No_, row.company, element2.person_responsible)" class="purchaserow"></tr>

              </table>

            </div>
          </div>
        </td>
      </ng-container>-->

      <tr class="mat-row" *matNoDataRow>
        <td class="mat-cell" colspan="3">No jobs available</td>
      </tr>


    </table>
    <div class="dashboard-spinner" *ngIf="isLoading">
      <mat-spinner ></mat-spinner> 

    </div>

</div>
