import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Title } from '@angular/platform-browser';
import { JsonPipe } from '@angular/common';

@Injectable({
  providedIn: 'root'
})
export class LocalisationService {
  content?:any;
  
  constructor(private http:HttpClient, private title:Title) {}

  refreshData() {
    if(localStorage.getItem("localisationcache")) {
      this.content = JSON.parse(localStorage.getItem("localisationcache") || "")
      this.title.setTitle(this.content.app_title)
      
    } else {
      this.http.get<any>('/api/v1/localisation?lang=' + navigator.language || window.navigator.language).subscribe(
        data => {
          this.content = data
          this.title.setTitle(data.app_title)
          localStorage.setItem("localisationcache", JSON.stringify(data))
          
        }
      )
    }
       
  }
}
